<template>
  <div>
    <kore-modal
      hideActions
      maxWidth="fit-content"
      :title="modalTitle"
      :value.sync="showModal"
    >
      <v-row>
        <v-col v-if="modalContent.length">
          <expansion-panel accordion :items="modalContent" multiple>
            <template v-slot:header="{ item: { name } }">
              {{ name }}
            </template>
            <template v-slot:content="{ item: { differences } }">
              <div class="difference" v-html="differences" />
            </template>
          </expansion-panel>
        </v-col>

        <v-col v-else>
          <p>Não foi possivel carregar as alterações.</p>
        </v-col>
      </v-row>
    </kore-modal>

    <master-detail
      :canDelete="false"
      :canEdit="false"
      :cols="cols"
      :customResource="customResource"
      :hasExportCSV="false"
      :hasNewButton="false"
      serverPagination
      @click="openModal"
    ></master-detail>
  </div>
</template>

<script>
import { BeneficiosEnum, BeneficiosLabels } from '@/core/enums/beneficios';
import { FieldTypeEnum } from '@/core/enums/field-types';
import { projectsOptions } from "@/helpers/yearsOptions";
import { diffLines, diffWordsWithSpace } from 'diff';
import { mapGetters } from 'vuex';
import { projetoAnaliseFields } from './projetos.analise.fields';
import { projetosEnquadradosFields } from './projetos.enquadrados.fields';
import { EnquadramentoEnum } from '@/core/enums/projetos';

export default {
  components: {
    'expansion-panel': () => import('@/components/expansion-panel.vue'),
    'kore-modal': () => import('@/components/kore-modal.vue'),
    'master-detail': () => import('@/components/master-detail.vue'),
  },
  computed: {
    ...mapGetters(['clientId']),
    cols: function () {
      return [
        {
          key: 'usuario',
          name: 'Usuário',
        },
        {
          key: 'agrupamento',
          name: 'Origem',
          type: this.$fieldTypes.SELECT,
          hideInTable: !this.isAgrupamento,
          rel: { key: "value", name: "label", toEdit: [
            {
              label: "Projeto agrupado",
              value: false,
            },
            {
              label: "Agrupamento",
              value: true,
            },
          ] },
        },
        {
          key: 'alteracoes',
          name: 'Alterações',
        },
        {
          align: 1,
          key: 'criado_em',
          name: 'Data',
          type: this.$fieldTypes.DATETIME,
        },
      ];
    },
    modalContent: function () {
      const { valores_antigos, valores_novos } = this.modalData;

      if (!valores_antigos || !valores_novos) {
        return [];
      }

      const existingKeys = Object.keys(this.projectsFields);
      const parsedContent = Object.keys(valores_antigos)
        .filter((key) => existingKeys.includes(key))
        .map((key) => {
          const { name, rel, type } = this.projectsFields[key];
          let valorAntigo = valores_antigos[key] || '';
          let valorNovo = valores_novos[key] || '';
          let differences = [];

          if (rel) {
            const { to, toEdit, key, name } = rel;
            const options = Array.isArray(toEdit) ? toEdit : Array.isArray(to) ? to : to in this.opts ? this.opts[to] : [];

            if (options.length && key && name) {
              [valorAntigo, valorNovo] = [valorAntigo, valorNovo].map((valor) => {
                const valores = Array.isArray(valor) ? valor : [valor];
                return valores.map((val) => {
                  const found = options.find((opt) => opt[key] === val);
                  return !found ? valor : found[name];
                });
              });
            }
          }

          if (![FieldTypeEnum.AUTOCOMPLETE_MULTIPLE, FieldTypeEnum.CHECKBOXES].includes(type)) {
            valorAntigo = valorAntigo.toString();
            valorNovo = valorNovo.toString();
          }

          switch(type) {
            case FieldTypeEnum.AUTOCOMPLETE_MULTIPLE:
            case FieldTypeEnum.CHECKBOXES:
              [valorAntigo, valorNovo] = [valorAntigo, valorNovo].map((opcoes) => opcoes.sort().reduce((acc, curr) => `${acc}${curr}\n`, ''));
              differences = diffLines(valorAntigo, valorNovo);
              break;
            case FieldTypeEnum.DATE:
              [valorAntigo, valorNovo] = [valorAntigo, valorNovo].map((valor) => this.$options.filters.toDate(valor));
              differences = diffLines(valorAntigo, valorNovo);
              break;
            default:
              if (['agrupados', 'status'].includes(key)) {
                differences = diffLines(valorAntigo, valorNovo);
              } else {
                differences = diffWordsWithSpace(valorAntigo, valorNovo);
              }
          }

          differences = differences
            .reduce((acc, { added, removed, value }) => {
              const parsedValue = value.replace(/[\r\n]/g, '&nbsp;<br />');
              const type = added ? 'added' : removed ? 'removed' : null;
              const part = !type ? parsedValue : `<span class="${type}">${parsedValue}</span>`;
              return acc + part;
            }, '');

          return {
            differences,
            name,
            type,
          };
        });

      return parsedContent;
    },
    modalTitle: function () {
      const titulo = this.modalData?.projeto || 'projeto';
      const data = this.$options.filters.toDateTime(this.modalData.criado_em) || 'data indefinida';
      return `Alterações em ${titulo} de ${data}`;
    },
    projetoId: function () {
      return this.$route.params.projetoId;
    },
    customResource: function () {
      const resource = this.apiResource(`/v1/projetos/${this.clientId}/historicos/${this.projetoId}`);
      const projectsFields = this.projectsFields;
      return {
        ...resource,
        get: function (...args) {
          return resource.get(...args).then((response) => {
            if (!('data' in response) || !Array.isArray(response.data)) {
              return response;
            }

            const { data, ...rest } = response;
            const parsedData = data.map((row) => {
              const fields = Object.keys(row.valores_antigos)
                .filter((key) => key in projectsFields)
                .map((key) => projectsFields[key].name);
              const parsedRow = { ...row, alteracoes: '' };

              // Formata lista de projetos de um agrupamento no formato `id — Título`
              if (Array.isArray(parsedRow.valores_novos.agrupados)) {
                [
                  parsedRow.valores_antigos.agrupados,  
                  parsedRow.valores_novos.agrupados, 
                ] = [
                  parsedRow.valores_antigos.agrupados,  
                  parsedRow.valores_novos.agrupados, 
                ]
                  .map((agrupados) => agrupados
                    .map(({ id, titulo }) => `${id || 0} — ${titulo}`)
                    .sort()
                    .join('\n')
                  );
              }

              if (fields.length > 3) {
                const [first, second, third, ...rest] = fields;
                parsedRow.alteracoes = `${first}, ${second}, ${third} e +${rest.length}`;
              } else if (fields.length > 1) {
                const last = fields.pop();
                parsedRow.alteracoes = `${fields.join(', ')} e ${last}`;
              } else if (fields.length) {
                parsedRow.alteracoes = `${fields[0]}`;
              } else {
                parsedRow.alteracoes = 'Nenhuma alteração detectada';
              }

              return parsedRow;
            });

            return { data: parsedData, ...rest };
          });
        }
      }
    },
    projectsFields: function () {
      const historyOnlyFields = [
        {
          key: 'agrupados',
          name: 'Projetos Agrupados',
        },
        {
          key: 'enquadramento',
          name: 'Enquadramento',
          rel: {
            toEdit: [
              { id: EnquadramentoEnum.ENQUADRADO, name: 'Enquadrado' },
              { id: EnquadramentoEnum.NENHUM, name: 'Pendente' },
              { id: EnquadramentoEnum.REPROVADO, name: 'Reprovado' },
            ],
            key: 'id',
            name: 'name',
          }
        },
      ];
      const handler = {
        get (target, key) {
          return key in target ?
            target[key] :
            {
              name: key,
              type: FieldTypeEnum.TEXT,
            };
        }
      };
      const target = projetoAnaliseFields
        .concat(projetosEnquadradosFields, historyOnlyFields)
        .reduce((acc, { key, name, rel, type }) => ({
          ...acc,
          [key]: {
            name: name.replace(/\(.*?\)/g, ''),
            type: type || FieldTypeEnum.TEXT,
            rel,
          },
        }), {});

      return new Proxy(target, handler);
    },
    isAgrupamento() {
      return !!this.$route.query.agrupamento;
    },
  },
  async created() {
    await this.getStatusProjetos();
  },
  data: function () {
    const beneficios = [
      {
        id: 0,
        nome: "Nenhum",
      },
      {
        id: BeneficiosEnum.LEI_DO_BEM,
        nome: BeneficiosLabels.LEI_DO_BEM,
      },
      {
        id: BeneficiosEnum.LEI_DE_INFORMATICA,
        nome: BeneficiosLabels.LEI_DE_INFORMATICA,
      },
      {
        id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
        nome: BeneficiosLabels.LEI_DO_BEM_E_INFORMATICA,
      },
    ];

    return {
      modalData: {},
      opts: {
        beneficios,
        projectsOptions,
        status: [],
        trabalho: beneficios,
      },
      showModal: false,
    };
  },
  methods: {
    getStatusProjetos() {
      return this.apiResource(`/v1/projetos/${this.clientId}/status`).get().then((result) => {
        this.opts.status = result;
        return result;
      });
    },
    openModal: function (value) {
      this.modalData = value;
      this.showModal = true;
    },
  },
}
</script>

<style lang="scss" scoped>
.difference ::v-deep {
  color: #5e5e5e;
  line-height: 1.4;

  &.added {
    background-color: #99d6b0;
    color: #1b6235;
  }
  
  &.removed {
    background-color: #ffb8b8;
    color: #5b2b2b;
  }
}
</style>
